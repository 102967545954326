import React, {useId, useState} from 'react';
import './SolutionCheckForm.css';
import Loader from "./Loader";

const SolutionCheckForm = ({type, title, description, firstnamePlaceholder, lastnamePlaceholder, defaultValue = {firstname:"", lastname:""}, onChange = () => {}}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [result, setResult] = useState("");
    const [resultColor, setResultColor] = useState("transparent");

    const id = useId();

    const onCheckSolution = ev => {
        ev.preventDefault();
        setIsLoading(true);
        fetch(
            "/api/solution",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    "type": type,
                    "firstname": ev.target.firstname.value,
                    "lastname": ev.target.lastname.value
                })
            }
        ).then(
            resp => resp.json()
        ).then(
            data => {
                setIsLoading(false);
                if (data.hasOwnProperty("success")) {
                    if (data.success) {
                        setResult("Vous avez trouvé la bonne personne !");
                        setResultColor("#22b800");
                    } else {
                        setResult("Ce n'est pas la bonne personne !");
                        setResultColor("#eb0000");
                    }
                } else {
                    setResult("Une erreur s'est produite. Veuillez réessayer plus tard...");
                    setResultColor("#eb0000");
                }
            }
        );
    };

    return (
        <section className="solution-checker">
            {isLoading && <Loader/>}
            <h2>{title}</h2>
            <p>
                {description}
            </p>
            <form onSubmit={onCheckSolution}>
                <div className="form-group">
                    <label htmlFor={`${id}-firstname`}>Prénom</label>
                    <input type="text" maxLength={32} id={`${id}-firstname`} name="firstname"
                           placeholder={firstnamePlaceholder} onChange={onChange} defaultValue={defaultValue.firstname}/>
                </div>
                <div className="form-group">
                    <label htmlFor={`${id}-lastname`}>Nom</label>
                    <input type="text" maxLength={32} id={`${id}-lastname`} name="lastname"
                           placeholder={lastnamePlaceholder} onChange={onChange} defaultValue={defaultValue.Lastname}/>
                </div>
                <input type="submit" value="Vérifier"/>
            </form>
            <p className="result" style={{display: result.length > 0 ? "block" : "none", "--color": resultColor}}>
                {result}
            </p>
        </section>
    );
};

export default SolutionCheckForm;
