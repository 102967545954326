import React from "react";
import './App.css';
import StorageManager from "./Utils/StorageManager";
import Header from './components/Header';
import QueryManager from "./components/QueryManager";
import SolutionCheckForm from "./components/SolutionCheckForm";

function App() {
    return (
        <>
            <Header/>
            <main>
                <QueryManager/>
                <SolutionCheckForm
                    type="victim"
                    title="La victime"
                    description="Vérifier l'identité de la victime avec le formulaire ci-dessous."
                    firstnamePlaceholder="Benoist"
                    lastnamePlaceholder="Auger"
                    defaultValue={StorageManager.getVictimName()}
                    onChange={ev => StorageManager.setVictimName(ev.target.name, ev.target.value)}
                />
                <SolutionCheckForm
                    type="murderer"
                    title="Le meurtrier"
                    description="Vérifier l'identité du meurtrier avec le formulaire ci-dessous."
                    firstnamePlaceholder="Sebastien"
                    lastnamePlaceholder="Dupont"
                    defaultValue={StorageManager.getMurdererName()}
                    onChange={ev => StorageManager.setMurdererName(ev.target.name, ev.target.value)}
                />
            </main>
            <footer>
                &copy; - CEFIM - 2023
            </footer>
        </>
    );
}

export default App;
