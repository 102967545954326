const QUERY_STORAGE_KEY = "query";
const COLLECTION_STORAGE_KEY = "collection";
const OPERATION_STORAGE_KEY = "operation";
const VICTIM_STORAGE_KEY = "victim";
const MURDERER_STORAGE_KEY = "murderer";

function getData(key, defaultValue = "") {
    const value = localStorage.getItem(key);
    if (value) {
        return value;
    } else {
        setData(key, defaultValue);
        return defaultValue;
    }
}

function setData(key, value) {
    localStorage.setItem(key, value);
}

class StorageManager {
    static getQuery() {
        return getData(QUERY_STORAGE_KEY, "");
    }

    static setQuery(query) {
        setData(QUERY_STORAGE_KEY, query);
    }

    static getCollection() {
        return getData(COLLECTION_STORAGE_KEY, "persons");
    }

    static setCollection(collection) {
        setData(COLLECTION_STORAGE_KEY, collection);
    }

    static getOperation() {
        return getData(OPERATION_STORAGE_KEY, "find");
    }

    static setOperation(operation) {
        setData(OPERATION_STORAGE_KEY, operation);
    }

    static getVictimName() {
        let firstname = "", lastname = "";
        try {
            const victim = JSON.parse(getData(VICTIM_STORAGE_KEY, JSON.stringify({
                "firstname": "",
                "lastname": ""
            })));

            firstname = victim.firstname;
            lastname = victim.lastname;
        } catch (e) {
            setData(VICTIM_STORAGE_KEY, JSON.stringify({
                "firstname": "",
                "lastname": ""
            }));
        }
        return {firstname: firstname, lastname: lastname};
    }

    static setVictimName(type, name) {
        const value = StorageManager.getVictimName();
        value[type] = name;
        setData(VICTIM_STORAGE_KEY, JSON.stringify(value));
    }

    static getMurdererName() {
        let firstname = "", lastname = "";
        try {
            const murderer = JSON.parse(getData(MURDERER_STORAGE_KEY, JSON.stringify({
                "firstname": "",
                "lastname": ""
            })));

            firstname = murderer.firstname;
            lastname = murderer.lastname;
        } catch (e) {
            setData(MURDERER_STORAGE_KEY, JSON.stringify({
                "firstname": "",
                "lastname": ""
            }));
        }
        return {firstname: firstname, lastname: lastname};
    }

    static setMurdererName(type, name) {
        const value = StorageManager.getVictimName();
        value[type] = name;
        setData(MURDERER_STORAGE_KEY, JSON.stringify(value));
    }
}

export default StorageManager;
