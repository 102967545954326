import React from 'react';
import './Header.css';
import logo from '../images/logo.png';

const Header = () => {
    return (
        <>
            <header>
                <h1>NoSQL Murder Mystery</h1>
                <img alt="Logo du CEFIM" src={logo} className="logo"/>
            </header>
            <section className="guidelines">
                <p>
                    Vos remarquables compétences d'enquéteur ont été mobilisées pour enquêter sur un meurtre récemment
                    commis dans la région. La police n'a malheureusement que peu d'informations à vous fournir. Tout ce
                    que vous savez, c'est que le corps a été retrouvé le <strong>21 avril 2023</strong>.
                </p>
                <p>
                    Pour mener à bien votre mission, le commissariat vous a tout de même donné accès à l'ensemble de la
                    base de données de la région centre. Comme il s'agit d'un service public, leurs bases de données
                    sont bien sûr <em>à la pointe de la téchnologie</em>. Ils ont abandonnés les vieux système SQL au
                    profit d'un tout nouveau système <strong>NoSQL</strong> tournant sous <strong>MongoDB</strong> !
                </p>
                <p>
                    Dans ce nouveau système, vous avez accès à <strong>5 collections</strong> de données :
                </p>
                <ul>
                    <li><code>car_registrations</code> : la liste des véhicule enregistrés à une adresse de la region.
                    </li>
                    <li><code>interviews</code> : l'ensemble des témoignage pour des delis ayant eu lieu dans la région.
                    </li>
                    <li><code>investigation_reports</code> : l'ensemble des rapports d'enquêtes ayant eu lieu dans la
                        région.
                    </li>
                    <li><code>persons</code> : la liste des informations sur chaque personnes recensé dans la région.
                    </li>
                    <li><code>phone_calls</code> : la liste des appels téléphoniques recensé par les opérateurs sur la
                        région.
                    </li>
                </ul>
                <p>
                    Pour mener à bien votre enquête, vous devrez donc procéder comme suit :
                </p>
                <ol>
                    <li>Trouver le <strong>rapport d'enquête</strong> et obtenir plus d'informations sur le meurtre.
                    </li>
                    <li>Retrouver l'identité de la <strong>victime</strong> (et la vérifier avec le formulaire
                        ci-dessous).
                    </li>
                    <li>Identifier le <strong>meurtrier</strong> (avec le second formulaire).</li>
                </ol>
                <p>
                    <em><strong>Bonus :</strong> Impressionnez l'ensemble du commissariat en tentant de résoudre
                        l'enquête en un minimum de requêtes !</em>
                </p>
            </section>
        </>
    );
};

export default Header;
