import React, {useEffect, useState} from 'react';
import AceEditor from "react-ace";
import "./QueryManager.css";
import StorageManager from "../Utils/StorageManager";
import Loader from "./Loader";

import "ace-builds/src-noconflict/mode-json5";
import "ace-builds/src-noconflict/theme-tomorrow";
import "ace-builds/src-noconflict/ext-language_tools";

const QueryManager = () => {
    const [whitelist, setWhitelist] = useState({"collections": [], "operations": []});
    const [collection, setCollection] = useState(StorageManager.getCollection());
    const [operation, setOperation] = useState(StorageManager.getOperation());
    const [query, setQuery] = useState(StorageManager.getQuery());
    const [isLoading, setIsLoading] = useState(true);
    const [result, setResult] = useState("");

    useEffect(() => {
        fetch("/api/whitelist")
            .then(resp => resp.json())
            .then(data => {
                setWhitelist(data);
                setIsLoading(false);
            });
    }, []);

    const onCollectionChange = ev => {
        if (whitelist.collections.includes(ev.target.value)) {
            StorageManager.setCollection(ev.target.value);
            setCollection(ev.target.value);
        } else {
            document.location.reload();
        }
    };

    const onOperationChange = ev => {
        if (whitelist.operations.includes(ev.target.value)) {
            StorageManager.setOperation(ev.target.value);
            setOperation(ev.target.value);
        } else {
            document.location.reload();
        }
    };

    const onQueryChange = code => {
        setQuery(code);
        StorageManager.setQuery(code);
    };

    const onSendQuery = () => {
        setIsLoading(true);
        setResult("");
        fetch(
            "/api/query",
            {
                method: "POST",
                headers: {
                    "content-type": "application/json"
                },
                body: JSON.stringify({
                    "collection": collection,
                    "operation": operation,
                    "query": query
                })
            }
        ).then(
            resp => resp.json()
        ).then(
            data => {
                setResult(JSON.stringify(data, null, 2));
                setIsLoading(false);
            }
        );
    };

    return (
        <section className="query-manager">
            {isLoading && <Loader/>}
            <h2>Editeur de requête</h2>
            <p>
                Envoyez vos requêtes et visualisez les résultat dans les éditeurs suivant. Utilisez les sélecteurs
                ci-dessous pour choisir la collection et l'opération à effectuer.
            </p>
            <p className="query-code">
                <span>db.</span>
                <select onChange={onCollectionChange} value={collection}>
                    {
                        whitelist.collections.map(
                            name => <option key={name}>{name}</option>
                        )
                    }
                </select>
                <span>.</span>
                <select onChange={onOperationChange} value={operation}>
                    {
                        whitelist.operations.map(
                            name => <option key={name}>{name}</option>
                        )
                    }
                </select>
                <span>(...);</span>
            </p>
            <AceEditor
                placeholder="{...}"
                mode="json5"
                theme="tomorrow"
                name="query-editor"
                className="code-editor"
                height="100%"
                width="100%"
                minLines={6}
                maxLines={200}
                onChange={onQueryChange}
                fontSize={14}
                showPrintMargin={true}
                showGutter={false}
                highlightActiveLine={true}
                value={query}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                enableSnippets={false}
                showLineNumbers={true}
                tabSize={2}
            />
            <button onClick={onSendQuery}>Envoyer</button>
            <AceEditor
                readOnly={true}
                placeholder="[{...}]"
                mode="json5"
                theme="tomorrow"
                name="query-editor"
                className="code-editor"
                height="100%"
                width="100%"
                wrapEnabled={true}
                minLines={6}
                maxLines={50}
                fontSize={14}
                showPrintMargin={true}
                showGutter={true}
                highlightActiveLine={true}
                value={result}
                enableBasicAutocompletion={true}
                enableLiveAutocompletion={true}
                enableSnippets={false}
                showLineNumbers={true}
                tabSize={2}
            />
        </section>
    );
};

export default QueryManager;
